<template>
  <div class="circle-details-container">
    <div class="circle-details-left">
      <div class="circle-details-module">
        <el-image class="circle-details-head" :src="userInfo.icon_tburl" />
        <div class="circle-details-info">
          <span>{{ userInfo.name }}</span>
          <span>ID: {{ userInfo.id }}</span>
        </div>
      </div>
      <div class="m-tb-15">圈子类型: 轻分享</div>
      <el-divider />
      <div class="circle-details-second-title">统计信息</div>
      <ul class="circle-details-statistics-info">
        <li>主题: {{ userInfo.post_count }}</li>
        <li>今日: {{ userInfo.post_today }}</li>
        <li>图片: {{ userInfo.post_pic_count }}</li>
        <li>粉丝: {{ userInfo.fans_count }}</li>
      </ul>
    </div>
    <div class="circle-details-right">
      <Details />
    </div>
  </div>
</template>

<script>
  import Details from './components/details'
  import { getCircleInfo } from '@/api/audit'
  export default {
    components: {
      Details,
    },
    data() {
      return {
        userInfo: {},
      }
    },
    created() {
      if (this.$route.query.circle_id) this.getInfo()
    },
    methods: {
      async getInfo() {
        const params = { id: this.$route.query.circle_id }
        const res = await getCircleInfo(params)
        if (res.success) this.userInfo = res.data
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.circle-details';
  #{$base}-container {
    display: flex;
    align-content: center;
    min-height: calc(100vh - 60px - 50px - 20px * 2) !important;
    #{$base}-left {
      width: 170px;
      height: 100%;
      padding-right: 10px;
      #{$base}-module {
        display: flex;
        align-content: center;
        span:first-child {
          font-size: 14px;
          font-weight: bold;
        }
      }
      #{$base}-head {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        border-radius: 5px;
      }
      #{$base}-info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 15px;
      }
      #{$base}-second-title {
        font-size: 14px;
        font-weight: bold;
      }
      #{$base}-statistics-info {
        padding-left: 20px;
        li {
          margin-bottom: 15px;
          font-size: 13px;
          color: $base-color-text-secondary;
        }
      }
      .m-tb-15 {
        margin: 15px 0;
        font-size: 13px;
        color: $base-color-text-secondary;
      }
    }
    #{$base}-right {
      flex: 1;
      padding-left: 10px;
      border-left: 1px solid #dcdfe6;
    }
  }
</style>
