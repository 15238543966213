var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "circle-details-container" }, [
    _c(
      "div",
      { staticClass: "circle-details-left" },
      [
        _c(
          "div",
          { staticClass: "circle-details-module" },
          [
            _c("el-image", {
              staticClass: "circle-details-head",
              attrs: { src: _vm.userInfo.icon_tburl },
            }),
            _c("div", { staticClass: "circle-details-info" }, [
              _c("span", [_vm._v(_vm._s(_vm.userInfo.name))]),
              _c("span", [_vm._v("ID: " + _vm._s(_vm.userInfo.id))]),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "m-tb-15" }, [_vm._v("圈子类型: 轻分享")]),
        _c("el-divider"),
        _c("div", { staticClass: "circle-details-second-title" }, [
          _vm._v("统计信息"),
        ]),
        _c("ul", { staticClass: "circle-details-statistics-info" }, [
          _c("li", [_vm._v("主题: " + _vm._s(_vm.userInfo.post_count))]),
          _c("li", [_vm._v("今日: " + _vm._s(_vm.userInfo.post_today))]),
          _c("li", [_vm._v("图片: " + _vm._s(_vm.userInfo.post_pic_count))]),
          _c("li", [_vm._v("粉丝: " + _vm._s(_vm.userInfo.fans_count))]),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "circle-details-right" }, [_c("Details")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }