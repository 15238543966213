var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "details" },
    [
      _c(
        "div",
        { staticClass: "user" },
        [
          _c("el-image", {
            staticClass: "details-head",
            attrs: { src: _vm.info.user ? _vm.info.user.head : "" },
          }),
          _c("div", { staticClass: "user-info" }, [
            _c("span", { staticClass: "username" }, [
              _vm._v(_vm._s(_vm.info.user ? _vm.info.user.name : "")),
            ]),
            _c("ul", { staticClass: "info" }, [
              _c("li", [
                _c("span", [_vm._v("发布时间:")]),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.info.create_time_str) +
                      " (" +
                      _vm._s(_vm.info.whole_create_time_str) +
                      ") "
                  ),
                ]),
              ]),
              _c("li", [
                _c("span", [_vm._v("发布圈子:")]),
                _c("span", [_vm._v(" " + _vm._s(_vm.info.circle_name) + " ")]),
              ]),
              _vm._m(0),
              _c("li", [
                _c("span", [_vm._v("收费金额:")]),
                _c("span", { staticClass: "color-E04A4A" }, [
                  _vm._v(_vm._s(_vm.info.price) + " 元"),
                ]),
              ]),
              _c("li", [
                _c("span", [_vm._v("购买人次:")]),
                _c("span", [_vm._v(_vm._s(_vm.info.pay_count) + " 人")]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("ul", { staticClass: "additional-info" }, [
        _c("li", [
          _c("span", [_vm._v("浏览:")]),
          _c("span", [_vm._v(_vm._s(_vm.info.click))]),
        ]),
        _c("li", [
          _c("span", [_vm._v("评论:")]),
          _c("span", [_vm._v(_vm._s(_vm.info.comment_count))]),
        ]),
        _c("li", [
          _c("span", [_vm._v("点赞:")]),
          _c("span", [_vm._v(_vm._s(_vm.info.applaud_count))]),
        ]),
        _c("li", [
          _c("span", [_vm._v("设备:")]),
          _c("span", [_vm._v(_vm._s(_vm.info.client_info))]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "thread-content" },
        [
          _vm.info.delete == 1
            ? [
                _vm.info.audit == 1
                  ? _c("el-tag", [_vm._v("待审核")])
                  : _vm._e(),
                _vm.info.audit == 2
                  ? _c("el-tag", { attrs: { type: "success" } }, [
                      _vm._v("已通过"),
                    ])
                  : _vm._e(),
                _vm.info.audit == 3
                  ? _c("el-tag", { attrs: { type: "danger" } }, [
                      _vm._v("未通过"),
                    ])
                  : _vm._e(),
                _vm.info.type == 2
                  ? _c("el-tag", { attrs: { type: "info" } }, [_vm._v("长文")])
                  : _vm._e(),
                _vm.info.type == 3
                  ? _c("el-tag", { attrs: { type: "info" } }, [_vm._v("文章")])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.info.delete == 2
            ? _c("el-tag", { attrs: { type: "success" } }, [
                _vm._v("用户已删除"),
              ])
            : _vm._e(),
          _c("p", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.info.content)),
          ]),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.info.video && _vm.info.type != 3,
              expression: "!info.video && info.type != 3",
            },
          ],
          staticClass: "pics",
        },
        [
          _vm.info.pics_arr && _vm.info.pics_arr.length
            ? _vm._l(_vm.info.pics_arr, function (item, index) {
                return _c("el-image", {
                  key: index,
                  staticClass: "pics-item",
                  attrs: { "preview-src-list": [item.tburl], src: item.tburl },
                })
              })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.info.video,
              expression: "info.video",
            },
          ],
          staticClass: "video",
        },
        [
          _c("vab-player-mp4", {
            attrs: { config: _vm.mp4Config },
            on: {
              player: function ($event) {
                _vm.$vabPlayerMap4 = $event
              },
            },
          }),
        ],
        1
      ),
      _c("el-divider"),
      _c("LikeContainer", {
        attrs: { id: _vm.$route.query.content_id, themetype: "show" },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [_vm._v("收费方式:")]),
      _c("span", [_vm._v("按字数")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }